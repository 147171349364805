export const dictionary = {
    'hy-AM': {
        translation: {

            'Все журналы': 'Բոլոր ամսագրերը',
            'Всё о спорте': 'Ամեն ինչ սպորտի մասին',
            'Всё о авто': 'Ամենինչ ավտոների մասին',
            'Всё о питании': 'Ամեն ինչ սննդի մասին',
            'Всё о гаджетах': 'Ամենինչ գաջեթների մասին',
            'Всё о стиле': 'Ամեն ինչ ոճի մասին',
            'Всё о космосе': 'Ամենինչ տիեզերքի մասին',
            'Всё об отдыхе': 'Ամենինչ հանգստի մասին',
            'На главную': 'Գլխավոր էջ',
            'Ещё по теме': 'Նույն թեմայով',
            'Чтиво': 'Ընթերցելու',
            'Читать далее': 'Կարդալ շարունակությունը',
            'Смотреть видео': 'Դիտել տեսանյութը',
            'Видео': 'Տեսանյութեր',
            'Условия предоставления контент-услуги': 'Կոնտենտ ծառայությունների մատուցման պայմանները',
            'Правила подписки': 'Բաժանորդագրման պայմանները',
            'Загрузка страницы...': 'Էջի ներբեռնում...',
            'Журнал загружается. Пожалуйста, подождите!': 'Ամսագիրը ներբեռնվում է: Խնդրում ենք սպասել',
            'Пожалуйста, подождите!': 'Խնդրում ենք մի քիչ սպասել',
            'Что-то': 'Ինչ-որ',
            'пошло не так': 'բան սխալ է',
            'Следующая статья': 'Հաջորդ հոդվածը',
            'Назад в раздел': 'Վերադառնալ բաժին',
            'Назад': 'Վերադառնալ',
            'Страница': 'Էջ',
            'из': 'ից',
            'Тег video не поддерживается вашим браузером': 'Տեսանյութը չի բացվում Ձեր բրաուզերում',
            'Пресса': 'Ամսագրեր',
            'Про спорт': 'Սպորտ',
            'Про автомобили': 'Ավտո',
            'Про стиль': 'Ոճ',
            'Про гаджеты': 'Գաջեթներ',
            'Про отдых': 'Հանգիստ',
            'Про космос': 'Տիեզերք',
            'Про питание': 'Սնունդ',
            'Музыка': 'Երաժշտություն',
            'Следующий трек': 'Հաջորդ ուղին',
            'ЛК': 'Ծառայությունների կառավարում',
            'Ссылка': 'Անձնական հաշվում',
        },
    },
    'en-GB': {
        translation: {

            'Все журналы': 'All magazines',
            'Всё о спорте': 'All about sports',
            'Всё о авто': 'All about cars',
            'Всё о питании': 'All about nutrition',
            'Всё о гаджетах': 'All about gadgets',
            'Всё о стиле': 'All about style',
            'Всё о космосе': 'Everything about space',
            'Всё об отдыхе': 'All about rest',
            'На главную': 'Home page',
            'Ещё по теме': 'More about this',
            'Чтиво': 'Reading',
            'Читать далее': 'Read more',
            'Смотреть видео': 'Watch the video',
            'Видео': 'Video',
            'Условия предоставления контент-услуги': 'Terms and Conditions of the content service provision',
            'Правила подписки': 'Terms of subscription',
            'Загрузка страницы...': 'Page is loading...',
            'Журнал загружается. Пожалуйста, подождите!': 'Magazine is downloading. Please wait!',
            'Пожалуйста, подождите!': 'Please wait!',
            'Что-то': 'Something',
            'пошло не так': 'went wrong',
            'Следующая статья': 'Next article',
            'Назад в раздел': 'Back to section',
            'Назад': 'Back',
            'Страница': 'Page',
            'из': 'from',
            'Тег video не поддерживается вашим браузером': 'Your browser is not supporting video format\n',
            'Пресса': 'Magazines',
            'Про спорт': 'Sport',
            'Про автомобили': 'Auto',
            'Про стиль': 'Style',
            'Про гаджеты': 'Gadgets',
            'Про отдых': 'Relaxation',
            'Про космос': 'Space',
            'Про питание': 'Food',
            'Музыка': 'Music',
            'Следующий трек': 'Next track',
            'ЛК': 'Manage the service in',
            'Ссылка': 'Personal Area',
        },
    },

    'ru-RU': {
        translation: {
            'Пресса': 'Журналы',
            'Про спорт': 'Спорт',
            'Про автомобили': 'Авто',
            'Про стиль': 'Стиль',
            'Про гаджеты': 'Гаджеты',
            'Про отдых': 'Отдых',
            'Про космос': 'Космос',
            'Про питание': 'Питание',
            'ЛК': 'Управление услугой в',
            'Ссылка': 'Личном кабинете',
        },
    },
};
