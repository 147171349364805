import React, { Component, ReactNode } from 'react';

import { ClassNameFormatter, cn } from '@bem-react/classname';
import { classnames } from '@bem-react/classnames';
import { IClassNameProps } from '@bem-react/core';
import * as Cookies from 'js-cookie';
import { Translation } from 'react-i18next';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

import { chooseAndTranslate } from '../../helpers/currentLanguageHelper';
import { getCategoryTitleText } from '../../helpers/getCategoryTitleText';
import { getCategoryLink, getContentLink } from '../../helpers/getLinks';
import { getRandomContentItem } from '../../helpers/getRandomContentItem';
import { ContentType, ICategory, IContentInfo, Languages, PageType } from '../../types/types';
import { HamburgerButton } from '../Buttons/HumburgerButton/HumburgerButton';
import { ContentAdditional } from '../ContentAdditional/ContentAdditional';
import { ContentDescription } from '../ContentDescription/ContentDescription';
import { ContentItemPreview } from '../ContentItemPreview/ContentItemPreview';
import { ContentItemPreviewContainer } from '../ContentItemPreview/ContentItemPreviewContainer';
import { ContentText } from '../ContentText/ContentText';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import { HeaderContainer } from '../Header/HeaderContainer';
// компоненты
import { HorizontalPreloader } from '../Preloader/HorizontalPreloader';

import './ContentPage.css';

// хэлперы

// стили

export interface IContentPageStateProps extends IClassNameProps {
    categoriesData: ICategory[];
    matchedCategory: ICategory;
    matchedContent: IContentInfo;
    alias: string;
    id: string;
    routeUrl: string;
    hash: string;
    text?: string;
    contentLoaded?: boolean;
    textError?: boolean;
    contents: {
        [key: string]: string;
    };
}

export interface IContentPageDispatchProps {
    onLoad(hash: string, routeUrl: string): void;
    onCheck(routeUrl: string): void;
}

export interface IContentPageProps extends IContentPageStateProps, IContentPageDispatchProps {
}

const contentPage: ClassNameFormatter = cn('ContentPage');

export class ContentPage extends Component<IContentPageProps> {
    componentDidMount() {
        window.scrollTo(0, 0);

        ((this.props.matchedContent.type === ContentType.type1) ||
            (this.props.matchedContent.type === ContentType.type2)) &&
            this.props.hash && this.props.contents[this.props.hash] === undefined &&
            this.props.onLoad(this.props.hash, this.props.routeUrl);

        (this.props.matchedContent.type === ContentType.type4) && this.props.onCheck(this.props.routeUrl);
    }

    componentDidUpdate(prevProps: IContentPageProps) {
        this.props.hash !== prevProps.hash && this.props.contents[this.props.hash] === undefined &&
            ((this.props.matchedContent.type === ContentType.type1) ||
                (this.props.matchedContent.type === ContentType.type2)) &&
            this.props.onLoad(this.props.hash, this.props.routeUrl);

        this.props.hash !== prevProps.hash && this.props.contents[this.props.hash] === undefined &&
            this.props.matchedContent.type === ContentType.type4 &&
            this.props.onCheck(this.props.routeUrl);
    }

    renderContentText = (description: string): ReactNode => {
        const { matchedContent, contentLoaded, hash, text } = this.props;

        if (contentLoaded &&
            (matchedContent.type === ContentType.type1 ||
                matchedContent.type === ContentType.type2)) {
            return (
                <div className={contentPage('ContentTextWrap')}>
                    <ContentText text={text} className={contentPage('ContentText')} />
                </div>
            )
        } else if (!contentLoaded &&
            (matchedContent.type === ContentType.type1 ||
                matchedContent.type === ContentType.type2)) {
            return (<HorizontalPreloader />)
        } else {
            let preview: string;
            matchedContent.previews[1] ?
                preview = `/storage/d/${matchedContent.previews[1].link}` :
                preview = `/storage/d/${matchedContent.previews[0].link}`;
            const src = '/gate/d/' + matchedContent.uidx + '/' + matchedContent.file_hash;
            return (
                <div className={contentPage('ContentTextWrap')}>
                    <video
                        className={contentPage('Video')} controls={true} controlsList='nodownload'
                        key={matchedContent.file_hash} poster={preview}>
                        <source src={src} type='video/mp4'
                            ref={() => Number(this.props.matchedContent.id)} />
                        Тег video не поддерживается вашим браузером
                    </video>
                </div>
            )
        }
    };

    getImageWidth = (contentType: string) => {
        if (contentType === ContentType.type1) {
            return '200px'
        } else if ((contentType === ContentType.type2 || (contentType === ContentType.type4))) {
            return '100%'
        } else {
            return '';
        }
    }

    render() {
        const { categoriesData, alias, id, matchedCategory, contentLoaded, matchedContent } = this.props;
        const linkToCategory = getCategoryLink(this.props.alias);
        const preview = matchedContent.previews[1] || matchedContent.previews[0];
        const itemStyle = {
            backgroundImage: `url(/storage/d/${preview.link})`,
            backgroundSize: 'cover',
            backgroundColor: '#dbdbdb',
            backgroundPositionY: '50%, 50%',
            backgroundPositionX: '50%, 50%',
            backgroundRepeat: 'no-repeat',
            width: this.getImageWidth(matchedContent.type),
            height: '220px',
            borderRadius: '10px',
        };

        if (this.props.textError && !this.props.contentLoaded) {
            return <ErrorPage />
        } else {
            {
                Cookies.remove('lastUrl');
            }
            return (
                <div className={contentPage()}>
                    <HeaderContainer
                        className={contentPage('Header')}
                        categoriesData={categoriesData}
                        page={PageType.typeContent}
                        categoryAlias={alias}
                        onChange={(lang: Languages) => {
                        }} />
                    <div className={contentPage('Content')}>
                        <Translation>
                            {
                                (t) =>
                                    <h2 className={classnames(contentPage('Title'),
                                        contentPage('type_' + this.props.alias))}>
                                        <Link to={`/category/${this.props.alias}`}
                                              className={contentPage('CategoryLink')} />
                                        {t(this.props.matchedCategory.name)}
                                    </h2>
                            }
                        </Translation>
                        <div className={contentPage('Wrapper')}>
                            <div className={contentPage('MainBar')}>
                                <div className={contentPage('ContentWrap')}>
                                    <LazyLoad scroll={true}>
                                        <div style={itemStyle} className={contentPage('Image')} />
                                    </LazyLoad>
                                    <div className={contentPage('ContentName')}>
                                        {this.props.matchedContent.name}
                                    </div>
                                    {this.renderContentText(matchedContent.description)}
                                </div>

                            </div>
                            <div className={contentPage('AdditionalBar')}>
                                <div className={contentPage('ContentAdditionalWrapper')}>
                                    <Translation>
                                        {
                                            (t) =>
                                                <h3 className={contentPage('ContentAdditionalTitle')}>
                                                    {t('Ещё по теме')}
                                                </h3>
                                        }
                                    </Translation>
                                    <ContentAdditional className={contentPage('ContentAdditional')}
                                        category={this.props.matchedCategory}
                                        contentId={Number(this.props.id)}
                                        page={contentPage()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}
