import {Dispatch} from 'redux';
import {IAppStore, ICategory, Languages} from '../../types/types';
import {CHANGE_LANGUAGE_START} from '../../actions/languageAction';
import {connect} from 'react-redux';
import {Header, IHeaderStateProps} from './Header';

export interface IOwnProps {
    page: string;
    categoriesData: ICategory[];
    categoryAlias?: string;
}

const mapStateToProps = (store: IAppStore, ownProps: IOwnProps): IHeaderStateProps => {
    return {
        ...ownProps,
        language: store.lang,
    }
}

const mapDispatchToProps = (dispatch: Dispatch): {} => ({
    onChange: (lang: Languages) => {
        dispatch({type: CHANGE_LANGUAGE_START, payload: lang})
    },
});

export const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(Header);
