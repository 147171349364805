import React, {Component} from 'react';

import {ClassNameFormatter, cn} from '@bem-react/classname';
import {IClassNameProps} from '@bem-react/core';

import {ICategory, Languages, PageType} from '../../types/types';
import {Header} from '../Header/Header';
import {HorizontalPreloader} from '../Preloader/HorizontalPreloader';

import './SubscriptionPage.css';
import {classnames} from '@bem-react/classnames';
import {HeaderContainer} from '../Header/HeaderContainer';

export interface ISubscriptionPageStateProps extends IClassNameProps {
    subscriptionText?: string;
    subscriptionTexts: {
        [key: string]: string;
    };
    error?: boolean;
    textLoaded: boolean;
    language: Languages;
    categoriesData?: ICategory[];
}

export interface ISubscriptionPageDispatchProps {
    onLoad(lang: Languages): void;
}

export interface ISubscriptionPageProps extends ISubscriptionPageStateProps, ISubscriptionPageDispatchProps {
}

const subscriptionPage: ClassNameFormatter = cn('SubscriptionPage');

export class SubscriptionPage extends Component <ISubscriptionPageProps> {
    componentDidMount() {
        const lang: Languages = this.props.language;
        this.props.subscriptionTexts[lang] === undefined && this.props.onLoad(lang);
        window.scrollTo(0, 0);
    }

    componentDidUpdate(prevProps: ISubscriptionPageProps) {
        const lang: Languages = this.props.language;
        this.props.language !== prevProps.language && this.props.subscriptionTexts[lang] === undefined &&
        this.props.onLoad(this.props.language);
    }

    createMarkup = (jsonString: any) => {
        return {__html: jsonString};
    };

    renderText = () => {
        if (this.props.textLoaded && this.props.subscriptionText) {
            return (
                <div
                    className={subscriptionPage('Text')}
                    dangerouslySetInnerHTML={this.createMarkup(this.props.subscriptionText)}
                />
            );
        } else {
            return (<HorizontalPreloader />);
        }
    };

    public render() {
        const {categoriesData} = this.props;
        if (categoriesData !== undefined) {
            return (
                <div className={classnames(subscriptionPage(), this.props.className)}>
                    <HeaderContainer
                        className={subscriptionPage('Header')}
                        categoriesData={categoriesData}
                        page={PageType.typeSubscription}
                        onChange={(lang: Languages) => {
                        }}/>
                    {this.renderText()}
                </div>
            );
        }
        return <HorizontalPreloader/>;
    }
}
